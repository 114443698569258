import { isDev } from 'utils/env'


// MQTT_JS_DEBUG is used in utils/streaming
// MQTT_DEBUG is used in reducers/data
const loggerEnabled = () => isDev || window.MQTT_DEBUG || window.MQTT_JS_DEBUG

export default function log(...args) {
  loggerEnabled() && console.log.apply(console, args)
}

export function groupLog(tag, ...args) {
  if (loggerEnabled()) {
    console.groupCollapsed(tag)
    log(...args)
    console.groupEnd()
  }
}

export function error(...args) {
  console.error.apply(console, args)
}
