import React from 'react'
import { connect } from 'react-redux'
import FontAwesome from 'react-fontawesome'

import FeedbackActions from 'actions/feedback'
import FeedbackTypes from './FeedbackTypes'
import FormButtons from './FormButtons'


class FeedbackForm extends React.Component {
  state = {
    form: {},
    error: null,
    errorMessage: null,
    enable: false,
  }

  inputs = new Map()

  goBack = () => this.props.dispatch(FeedbackActions.toStep('pick_type'))

  submit = evt => {
    evt.preventDefault()

    if (!this.state.enable) { return }

    const form = {}

    Array.from(this.inputs.values())
         .filter(i => i !== null)
         .forEach(i => {
           if (i.multiple) {
             let vals = []
             for (let n = 0; n < i.options.length; n++) {
               if (i.options.item(n).selected) {
                 vals.push(i.options.item(n).value)
               }
             }
             form[i.name] = vals.join(',')
           } else if (i.type === 'checkbox' && i.checked) {
             form[i.name] = i.value
           } else {
             form[i.name] = i.value
           }
         });

    this.props.dispatch(FeedbackActions.create(form)).then(() => {
      this.props.dispatch(FeedbackActions.toStep('thanks', this.props.subject))
    }).catch(ex => {
      this.setState({
        error: ex,
        errorMessage: "There was a problem saving this message. Please try again or get in touch with us on the forums."
      })
    })
  }

  register = input => {
    if (!input) { return }

    this.inputs.set(input.name, input)
  }

  subject() {
    switch (this.props.subject) {
      case FeedbackTypes.SERVICE:
        return 'There is an issue with Adafruit IO. Something is broken!'
      case FeedbackTypes.WIPPERSNAPPER:
        return 'There is an issue with WipperSnapper. Something is broken!'
      case FeedbackTypes.WIPPER_SUGGESTION:
        return 'I have feedback or suggestions for the WipperSnapper Beta'
      case FeedbackTypes.PLUS:
        return 'I have a question about Adafruit IO+'
      case FeedbackTypes.GENERAL:
        return 'I have a general question about Adafruit IO'
      case FeedbackTypes.SUGGESTION:
        return 'I have a suggestion for Adafruit IO'
      case FeedbackTypes.PROJECT:
        return 'I need help with my project hardware or code'
      case FeedbackTypes.OTHER:
        return ''
      case FeedbackTypes.BETA:
        return 'I would like to participate in a private beta'
      default:
        return 'I need help with Adafruit IO'
    }
  }

  description() {
    if (this.state.error) {
      return (
        <div className='error'>
          <p>{ this.state.errorMessage }</p>
        </div>
      )
    }

    switch (this.props.subject) {
      case FeedbackTypes.SERVICE:
        return (
          <div>
            <p>
              Oh no! It sounds like something might be broken. Please try to be
              specific about the problem you're seeing including links to the
              page you're having trouble on and the code you're using, if that
              applies.
            </p>

            <p>
              After leaving a note here, it would also be good to <a href={import.meta.env.VITE_FORUMS_URL} target="_blank" rel="noopener">search the forums</a> to
              see if anyone else has run into the same problem recently.
            </p>
          </div>
        )
      case FeedbackTypes.WIPPERSNAPPER:
        return (
          <div>
            <p>
              Oh no! It sounds like something might be broken with WipperSnapper.
              Thank you for your patience and willingness to help as we get
              through the Beta process! Please try to be specific about the
              problem you're seeing including links to the page you're having
              trouble on and the code you're using, if that applies.
            </p>

            <p>
              After leaving a note here, it would also be good to <a href={ import.meta.env.VITE_WIPPER_FORUMS_URL } target="_blank" rel="noopener">search the WipperSnapper forums</a> to
              see if anyone else has run into the same problem recently.
            </p>
          </div>
        )
      case FeedbackTypes.WIPPER_SUGGESTION:
        return (
          <div>
            <p>
              Cool! We love new ideas and are glad to have a supportive community.
            </p>

            <p>
              We can't wait to see what you have in mind! Please be aware that
              while we read and consider every message, we aren't able to
              respond directly to every feature request.
            </p>
          </div>
        )
      case FeedbackTypes.PLUS:
        return (
          <div>
            <p>
              Adafruit IO+ is our paid, higher access level plan. We're happy
              to answer any questions you have about it or help if you're
              having problems with accessing IO+.
            </p>
            <p>
              Please do not include any personal information or account details
              in your feedback.
            </p>
          </div>
        )
      case FeedbackTypes.OTHER:
      case FeedbackTypes.GENERAL:
        return (
          <div>
            <p>
              For general questions about Adafruit IO, our IO-friendly open
              source code libraries, or Adafruit hardware, we would prefer to
              answer your questions <a href={import.meta.env.VITE_FORUMS_URL} target="_blank" rel="noopener">on the forums</a> so
              that the whole community can benefit.
            </p>

            <p>
              Due to the amount of support requests we receive, we are not able
              to respond directly to every message.
            </p>
          </div>
        )
      case FeedbackTypes.SUGGESTION:
        return (
          <div>
            <p>
              Cool! We love new ideas and are glad to have a supportive community.
            </p>

            <p>
              Please be aware that we're careful about what features we can add
              and how we make changes, and while we appreciate your input, we
              aren't able to respond directly to every feature request.
            </p>
          </div>
        )
      default:
        // should not end up here
    }
  }

  backButton() {
    // default type means forced feedback form process
    if (!this.props.selectedType) {
      return (
        <button className='btn' onClick={this.goBack}>
          <FontAwesome name='chevron-left' /> GO BACK
        </button>
      )
    }
  }

  subjectSection() {
    const hideInput = (this.props.subject !== FeedbackTypes.OTHER),
          inputType = hideInput ? 'hidden' : 'text'

    return (
      <div className="form-group">
        <label htmlFor='subject'>Subject</label>
        <input className='form-control'
               name='subject'
               type={inputType}
               ref={this.register}
               defaultValue={this.subject()} />
        { hideInput && <p>{ this.subject() }</p> }
      </div>
    )
  }

  render() {
    return (
      <form className='form feedback-form' onSubmit={this.submit}>

        { this.description() }
        { this.subjectSection() }

        <div className="form-group">
          <label htmlFor='message'>Message</label>
          <textarea className='form-control' name='message' ref={this.register} onChange={() => this.setState({ enable: true })}></textarea>
        </div>

        <div className="checkbox">
          <label>
            <input type='checkbox' name='contact_request' ref={this.register} value='1' />
            It is okay to contact me at my Adafruit account email
          </label>
        </div>

        <FormButtons>
          { this.backButton() }
          <button className='btn blue' disabled={!this.state.enable}>SUBMIT</button>
        </FormButtons>
      </form>
    )
  }
}

const mapState = ({ feedback }, { selectedType }) => ({
  step: feedback.step,
  subject: selectedType || feedback.subject
})

const ConnectedFeedbackForm = connect(mapState)(FeedbackForm)
export default ConnectedFeedbackForm
