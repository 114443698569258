import { handleActions } from 'redux-actions';

const initialState = {
  error: null,
  kiosk: false
};

export default handleActions({
  BROWSING_NOT_FOUND(state) {
    return Object.assign({}, state, {
      error: 'not_found'
    });
  },

  BROWSING_RESET(state) {
    return Object.assign({}, state, {
      error: null
    });
  },

  BROWSING_SET_KIOSK(state, { payload }) {
    return Object.assign({}, state, {
      kiosk: payload
    })
  },
}, initialState);
