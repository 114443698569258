import { includes, some } from 'lodash'
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import useDelayedState from 'effects/useDelayedState'
import Navigation from 'utils/navigation'
import { Paths } from 'utils/paths'
import FeedbackActions from 'actions/feedback'
import GuideActions from 'actions/guides'
import logException from 'utils/errors'

import ioLogo from 'images/logos/io-logo-48-white.png'
import 'stylesheets/footer.scss'


const
  IOLink = ({ to, children }) => (
    <a href={ Paths[to]() } onClick={ evt => {
      evt.preventDefault()
      Navigation.go(Paths[to]())
    }}>{ children }</a>
  ),

  ExternalLink = ({ to, children, title }) => (
    <a target="_blank" rel="noopener" href={ to } title={ title }>{ children }</a>
  ),

  SiteStatusLink = () => {
    const [sites, setSites] = useState(null)

    useEffect(() => {
      if (window.SITE_STATUS) {
        setSites(window.SITE_STATUS)
      }
    }, [window])

    if(!sites) { return }

    const statuses = Object.values(sites)
    let color, message

    if(!statuses.length) {
      // no info yet
      color = 'gray'
      message = "Awaiting site status info..."

    } else if(includes(statuses, 'down')) {
      // red if anything is down
      color = 'red'
      message = "Some services are down, click for more info."

    } else if(some(statuses, s => s !== 'up')) {
      // yellow if we're not all up
      color = 'yellow'
      message = "Some services are degraded, click for more info."

    } else {
      // green when we're all up
      color = 'green'
      message = "All services are available. Click for more info."
    }

    return <ExternalLink to="https://www.adafruitstatus.com" title={ message }>
      IO Status <i className="fa fa-circle" style={{ color }} />
    </ExternalLink>
  },

  SocialLink = ({ to, label, icon, children, ...rest }) => (
    <a href={ to } aria-label={ label } target="_blank" rel="noopener noreferrer" { ...rest }>
      { icon && <i className={ `fa fa-${ icon }`} aria-hidden="true" /> }
      { children }
    </a>
  ),

  Quote = () => {
    const [quote, setQuote] = useState(null)

    useEffect(() => {
      if (window.FOOTER_QUOTE?.quote) {
        setQuote(window.FOOTER_QUOTE.quote)
      }
    }, [window])

    if(!quote) { return }

    try {
      const
        separated = quote.split(" - "),
        words = separated.slice(0, separated.length-1).join((" - ")),
        author = separated[separated.length - 1],
        quoteClass = words.length > 125 ? 'quote long-quote' : 'quote normal'

      return (
        <aside>
          <div className={ quoteClass } dangerouslySetInnerHTML={{ __html: words }} />
          <div className="quote-attribution" dangerouslySetInnerHTML={{ __html: author }} />
        </aside>
      )
    } catch (ex) {
      logException(ex, { quote })
      return null
    }
  },

  Footer = () => {
    const
      ready = useDelayedState(false, true, 200),
      dispatch = useDispatch(),
      showGuides = () => dispatch(GuideActions.showGuide('meta')),
      showFeedback = () => dispatch(FeedbackActions.toggleModal(true, 'root'))

    if(!ready) { return } // avoid flash of unstyled content

    return (
      <footer id="adafruit-footer">
        <div className="footer-container">
          <div className="application-support-link">
            <div>
              <ul>
                <li><IOLink to="support">Get Help</IOLink></li>
                <li><a onClick={ showGuides }>Quick Guides</a></li>
                <li><ExternalLink to={ import.meta.env.VITE_DOCUMENTATION_URL }>API Documentation</ExternalLink></li>
                <li><IOLink to="faq">FAQ</IOLink></li>
                <li><ExternalLink to="https://www.adafruit.com/free">Freebies</ExternalLink></li>
                <li><IOLink to="terms">Terms of Service</IOLink></li>
                <li><ExternalLink to="https://www.adafruit.com/privacy">Privacy Policy</ExternalLink></li>
                <li><ExternalLink to="https://www.adafruit.com/accessibility">Website Accessibility</ExternalLink></li>
                <li><a onClick={ showFeedback }>Send Feedback</a></li>
              </ul>
            </div>

            <div>
              <ul>
                <li><SiteStatusLink /></li>
                <li><ExternalLink to={ import.meta.env.VITE_LEARN_URL }>Learn</ExternalLink></li>
                <li><IOLink to="plus">IO Plus</IOLink></li>
                <li><ExternalLink to="https://io.adafruit.com/blog">News</ExternalLink></li>
              </ul>
            </div>
          </div>

          <Quote />
        </div>

        <div className="footer-container bottom">
          <a title="About Adafruit" id="footer-logo" href="https://io.adafruit.com">
            <img src={ioLogo} alt="Adafruit IO Logo" />
          </a>

          <div className="social-links">
            <SocialLink to="https://www.instagram.com/adafruit/" label="Adafruit on Instagram" icon="instagram" />
            <SocialLink to="https://twitter.com/adafruit/" label="Adafruit on Twitter" icon="twitter" />
            <SocialLink to="https://mastodon.cloud/@adafruit" label="Adafruit on Mastodon" className="mastodon-logo" rel="me">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 216.4144 232.00976"><path className="logo" d="M211.80734 139.0875c-3.18125 16.36625-28.4925 34.2775-57.5625 37.74875-15.15875 1.80875-30.08375 3.47125-45.99875 2.74125-26.0275-1.1925-46.565-6.2125-46.565-6.2125 0 2.53375.15625 4.94625.46875 7.2025 3.38375 25.68625 25.47 27.225 46.39125 27.9425 21.11625.7225 39.91875-5.20625 39.91875-5.20625l.8675 19.09s-14.77 7.93125-41.08125 9.39c-14.50875.7975-32.52375-.365-53.50625-5.91875C9.23234 213.82 1.40609 165.31125.20859 116.09125c-.365-14.61375-.14-28.39375-.14-39.91875 0-50.33 32.97625-65.0825 32.97625-65.0825C49.67234 3.45375 78.20359.2425 107.86484 0h.72875c29.66125.2425 58.21125 3.45375 74.8375 11.09 0 0 32.975 14.7525 32.975 65.0825 0 0 .41375 37.13375-4.59875 62.915" fill="currentColor"/><path d="M177.50984 80.077v60.94125h-24.14375v-59.15c0-12.46875-5.24625-18.7975-15.74-18.7975-11.6025 0-17.4175 7.5075-17.4175 22.3525v32.37625H96.20734V85.42325c0-14.845-5.81625-22.3525-17.41875-22.3525-10.49375 0-15.74 6.32875-15.74 18.7975v59.15H38.90484V80.077c0-12.455 3.17125-22.3525 9.54125-29.675 6.56875-7.3225 15.17125-11.07625 25.85-11.07625 12.355 0 21.71125 4.74875 27.8975 14.2475l6.01375 10.08125 6.015-10.08125c6.185-9.49875 15.54125-14.2475 27.8975-14.2475 10.6775 0 19.28 3.75375 25.85 11.07625 6.36875 7.3225 9.54 17.22 9.54 29.675" fill="#000"/></svg>
            </SocialLink>
            <SocialLink to="https://www.facebook.com/adafruitindustries" label="Adafruit on Facebook" icon="facebook" />
            <SocialLink to="https://www.youtube.com/adafruit" label="Adafruit on YouTube" icon="youtube" />
            <SocialLink to="https://github.com/adafruit" label="Adafruit on Github" icon="github" />
            <SocialLink to="https://www.tiktok.com/@adafruit" label="Adafruit on TikTok" className="tiktok-logo">
              <svg viewBox="3 2 20 22" xmlns="http://www.w3.org/2000/svg"><path className="logo" d="M19.8059 7.50695C18.7771 7.50695 17.8278 7.16612 17.0655 6.59113C16.1912 5.93198 15.5631 4.9651 15.3412 3.85069C15.2863 3.57534 15.2567 3.29119 15.2539 3H12.3149V11.0308L12.3114 15.4297C12.3114 16.6057 11.5455 17.6029 10.4839 17.9536C10.1758 18.0553 9.8431 18.1036 9.49663 18.0846C9.05438 18.0603 8.63995 17.9268 8.27975 17.7113C7.51322 17.2529 6.99351 16.4212 6.97942 15.4698C6.95724 13.9829 8.15933 12.7706 9.64522 12.7706C9.93852 12.7706 10.2202 12.8185 10.4839 12.9054V10.7104V9.92134C10.2058 9.88015 9.92268 9.85867 9.63641 9.85867C8.01004 9.85867 6.48894 10.5347 5.40164 11.7526C4.57982 12.6731 4.08687 13.8473 4.01082 15.0786C3.91117 16.6962 4.50306 18.2339 5.65093 19.3683C5.81959 19.5349 5.9967 19.6895 6.1819 19.8321C7.16604 20.5894 8.36919 21 9.63641 21C9.92268 21 10.2058 20.9789 10.4839 20.9377C11.6677 20.7623 12.7599 20.2204 13.6219 19.3683C14.681 18.3215 15.2662 16.9318 15.2726 15.4526L15.2574 8.88369C15.7627 9.27347 16.3152 9.596 16.9081 9.84635C17.8303 10.2354 18.8081 10.4326 19.8144 10.4322V8.29814V7.50625C19.8151 7.50695 19.8066 7.50695 19.8059 7.50695Z" fill="currentColor"/></svg>
            </SocialLink>
            <SocialLink to="https://adafruit.tumblr.com" label="Adafruit on Tumblr" className="tumblr-logo" icon="tumblr" />
            <SocialLink to="https://adafru.it/discord" label="Adafruit on Discord" className="discord-logo">
              <span aria-hidden="true"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 127.14 96.36"><path className="logo" d="M107.7,8.07A105.15,105.15,0,0,0,81.47,0a72.06,72.06,0,0,0-3.36,6.83A97.68,97.68,0,0,0,49,6.83,72.37,72.37,0,0,0,45.64,0,105.89,105.89,0,0,0,19.39,8.09C2.79,32.65-1.71,56.6.54,80.21h0A105.73,105.73,0,0,0,32.71,96.36,77.7,77.7,0,0,0,39.6,85.25a68.42,68.42,0,0,1-10.85-5.18c.91-.66,1.8-1.34,2.66-2a75.57,75.57,0,0,0,64.32,0c.87.71,1.76,1.39,2.66,2a68.68,68.68,0,0,1-10.87,5.19,77,77,0,0,0,6.89,11.1A105.25,105.25,0,0,0,126.6,80.22h0C129.24,52.84,122.09,29.11,107.7,8.07ZM42.45,65.69C36.18,65.69,31,60,31,53s5-12.74,11.43-12.74S54,46,53.89,53,48.84,65.69,42.45,65.69Zm42.24,0C78.41,65.69,73.25,60,73.25,53s5-12.74,11.44-12.74S96.23,46,96.12,53,91.08,65.69,84.69,65.69Z" fill="currentColor"/></svg></span>
            </SocialLink>
          </div>

          <div className="footer-messaging">
            <a href="https://www.adafruit.com/about">A Minority and Woman-owned Business Enterprise (M/WBE)</a>
          </div>
        </div>
      </footer>
    )
  }

export default Footer
