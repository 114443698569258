import { difference, every, filter, find, flatten, get, identity, includes, isEmpty, map, property, reject, uniq, values } from 'lodash'
import { createSelector, createSlice } from '@reduxjs/toolkit'

import { getBoardByName, getUARTPinsForBoard } from 'slices/boards'
import { usernameSelector } from 'selectors/users'
import GroupActions from 'actions/groups'
import { notifyConnected, notifyDisconnected } from 'components/WipperSnapper/connection_notifiers'


const { reducer, actions } = createSlice({
  name: 'devices',

  initialState: {
    connections: [],
  },

  reducers: {
    setConnections: (state, { payload: connections }) => ({ ...state, connections }),
  },
})

export const
  // ACTIONS
  refreshDevice = deviceKey => (dispatch, getState) => {
    const
      state = getState(),
      username = usernameSelector(state)

    return dispatch(GroupActions.get({ username, group_key: deviceKey }))
  },

  refreshCurrentDevice = () => (dispatch, getState) => {
    const
      state = getState(),
      device = selectCurrentDevice(state)

    return device ? dispatch(refreshDevice(device.key)) : Promise.resolve()
  },

  clearCurrentDevice = () => dispatch => dispatch(GroupActions.groupsClearCurrent()),

  updateDeviceConnections = newConnections => (dispatch, getState) => {
    // analyze connection changes
    const
      oldConnections = selectConnections(getState()),
      connected = difference(newConnections, oldConnections),
      disconnected = difference(oldConnections, newConnections)

    // notify the connections and disconnections
    connected.forEach(notifyConnected)
    disconnected.forEach(notifyDisconnected)

    // call through to set the state
    dispatch(actions.setConnections(newConnections))
  },

  // SELECTORS
  devicesState = property("devices"),

  // Groups with a machine_name are actually wippers
  selectDevices = state => filter(get(state, "groups.groups", []), "machine_name"),

  selectConnections = createSelector([devicesState], property('connections')),

  selectCurrentDevice = state => {
    const currentGroup = get(state, "groups.group", {})

    return currentGroup.machine_name ? currentGroup : null
  },

  selectCurrentDeviceBoardName = createSelector(
    [selectCurrentDevice], property("machine_name")
  ),

  selectCurrentDeviceBoard = createSelector(
    [identity, selectCurrentDeviceBoardName], getBoardByName
  ),

  selectCurrentDeviceBoardMagic = createSelector(
    [selectCurrentDeviceBoard], property("magic")
  ),

  selectCurrentDeviceSemver = createSelector(
    [selectCurrentDevice], property("wipper_semver")
  ),

  selectCurrentDeviceComponents = createSelector([selectCurrentDevice], device => device?.feeds || []),

  selectCurrentDeviceBuiltInComponentTypes = createSelector(
    [selectCurrentDeviceBoardMagic], magic => {
      return magic?.components?.length
        ? uniq(map(magic.components, ({ type }) => type.split(':')[0]))
        : []
  }),

  selectCurrentDeviceOnline = createSelector(
    [identity, selectCurrentDevice],
    (state, device) => isDeviceOnline(state, device?.key)
  ),

  // Returns the analog and digital pin collections from the Board definition
  // with a "used" boolean added that reflects whether the pin already has
  // a wipper component connected to it
  selectCurrentDevicePinStatus = createSelector(
    [selectCurrentDeviceBoard, selectCurrentDeviceComponents],
    (board, components) => {
      if(!board) { return { analog: [], digital: [], pwm: [], servo: [] }}

      const
        { digitalPins, analogPins } = board.components,
        usedPinNames = flatten([
          map(components, "wipper_pin_info.pinName"),
          map(components, "wipper_pin_info.dataPinName"),
          map(components, "wipper_pin_info.clockPinName"),
          map(components, "wipper_pin_info.uartTx"),
          map(components, "wipper_pin_info.uartRx"),
        ]),
        addUsedMapper = collection => map(collection, pin => ({
          ...pin,
          used: includes(usedPinNames, pin.name)
        })),
        digital = addUsedMapper(digitalPins),
        analog = addUsedMapper(analogPins),
        allPins = digital.concat(analog),
        pwm = filter(allPins, 'hasPWM'),
        servo = filter(allPins, 'hasServo')

      return { analog, digital, pwm, servo }
    }
  ),

  selectCurrentDeviceAvailablePins = createSelector([selectCurrentDevicePinStatus], currentPins => ({
    analog: reject(currentPins.analog, "used"),
    digital: reject(currentPins.digital, "used"),
  })),

  selectCurrentDeviceI2CSettings = createSelector(
    [selectCurrentDeviceBoard], board => board?.components?.i2cPorts?.[0]
  ),

  selectCurrentDeviceSupportsI2C = createSelector(
    [selectCurrentDeviceI2CSettings], i2cSettings => !isEmpty(i2cSettings)
  ),

  selectCurrentDeviceIsI2CReady = createSelector(
    [selectCurrentDeviceOnline, selectCurrentDeviceSupportsI2C],
    (connected, supported) => (connected && supported)
  ),

  selectCurrentDeviceUARTPins = createSelector(
    [identity, selectCurrentDeviceBoardName], getUARTPinsForBoard
  ),

  // QUERIES
  hasDeviceByKey = (state, key) => !!find(selectDevices(state), { key }),

  isDeviceOnline = (state, deviceKey) => includes(selectConnections(state), deviceKey),

  getComponentTypeAvailability = (state, componentType) => {
    let available = true
    const messages = []

    // I2C not ready
    if(componentType.isI2C && !selectCurrentDeviceSupportsI2C(state)) {
      available = false
      messages.push({
        short: "I2C Unsupported",
        long: "This device does not support I2C components."
      })
    }

    if(componentType.isUART) {
      if(find(selectCurrentDeviceComponents(state), { wipper_pin_info: { isUART: true } })) {
        // already have UART component
        available = false
        messages.push({
          short: "Limit 1 UART Component",
          long: "This device already has a UART component configured. Remove it to add another."
        })
      }

      const uartPins = selectCurrentDeviceUARTPins(state)
      if(!uartPins) {
        // no UART pins specified
        available = false
        messages.push({
          short: "UART Unsupported",
          long: "This device does not support UART components."
        })

      } else {
        const
          { analog, digital } = selectCurrentDeviceAvailablePins(state),
          allPins = digital.concat(analog),
          availablePinNames = map(reject(allPins, "used"), "name"),
          uartPinNames = values(uartPins),
          uartAvailable = every(uartPinNames, uartPin => includes(availablePinNames, uartPin))

        if(!uartAvailable) {
          // UART pins in use
          available = false
          messages.push({
            short: "UART Pins In Use",
            long: "This device's UART pins are already in use by another component."
          })
        }
      }
    }

    return { available, messages }
  },

  hasBuiltInComponentType = (state, componentType) => {
    const componentTypes = selectCurrentDeviceBuiltInComponentTypes(state)
    return includes(componentTypes, componentType)
  }

export default reducer
