import { forEach } from 'lodash'
import * as Sentry from '@sentry/browser'
import { BrowserTracing } from "@sentry/tracing"

import { isProd, isStaging } from 'utils/env'


const REPORT_ERRORS = isProd || isStaging

if(REPORT_ERRORS) {
  Sentry.init({
    environment: isProd ? 'production' : 'staging',
    release: import.meta.env.VITE_APP_VERSION,
    dsn: 'https://e8b725bbf8564097a0ac83f3fc638049@o963192.ingest.sentry.io/5911459',
    integrations: [new BrowserTracing()],
    tracesSampleRate: isProd ? 0.05 : 1.0,
  })

  Sentry.addGlobalEventProcessor(function (event, hint) {
    if(event.type === 'transaction') { return event }

    // collaborate with our ajax wrapper in ajax.js
    const { originalException } = hint
    if(originalException?.name === "IOAjaxError") {
      event.contexts = {
        ...event.contexts,
        ...originalException.context
      }
    }

    return event;
  })
}

const
  attachUserToErrorReports = ({ id, username, email }) => {
    REPORT_ERRORS && Sentry.setUser({ id, username, email })
  },

  assertAndLog = (assertion, errorMessage='An assertion failed.') => {
    if(assertion) { return }

    logException(new Error(errorMessage))
  },

  logException = (ex, extras={}) => {
    window.console?.error(ex, extras)

    if(!REPORT_ERRORS) { return }

    Sentry.withScope(scope => {
      // unpack extras
      forEach(extras, (value, key) => {
        scope.setExtra(key, value)
      })
      Sentry.captureException(ex)
    })
  }

export default logException
export { assertAndLog, attachUserToErrorReports, logException }
