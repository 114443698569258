import { property } from 'lodash'
import { createSelector } from '@reduxjs/toolkit'

import { ProfileSelectors  } from 'reducers/profiles'
import { hasBetaAccess } from 'utils/beta'


export const
  aioKeySelector = state => state.keys.keys[0]?.key,

  userSelector = state => state.session.user,

  usernameSelector = createSelector(userSelector, property("username")),

  userTimezoneSelector = createSelector(userSelector, property("time_zone")),

  isAdmin = createSelector(userSelector, user => user?.role === "admin"),

  hasIoPlusSelector = createSelector(ProfileSelectors.subscriptionSelector,
    (subscription={}) => (subscription.status === 'active') && subscription.plan.paid
  ),

  canSeeDesignGuide = isAdmin,

  // queries
  queryBetaFlag = (state, betaFlag) => {
    // get the user from the state
    const user = userSelector(state)
    // call hasBetaAccess helper
    return hasBetaAccess(user, betaFlag)
  }
