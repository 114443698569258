import PropTypes from 'prop-types'
import React from 'react'
import hotkeys from 'hotkeys-js'
import { Router } from 'react-router'
import { connect } from 'react-redux'

import ErrorBoundary from 'components/TopLevel/ErrorBoundary'
import Header from 'components/Header'
import Footer from 'components/Footer'
import SessionActions from 'actions/session'
import BrowsingActions from 'actions/browsing'
import GuideViewer from 'components/GuideViewer/Viewer'
import FeedbackCreator from 'components/Feedback/FeedbackCreator'
import ConnectionStatusBar from 'components/Elements/ConnectionStatusBar'
import WipperForm from 'components/WipperSnapper/Form'
import SystemMessages from 'components/Elements/SystemMessages';
import { Spinner } from 'components/Elements/Loading'

import 'stylesheets/application.scss'


class Root extends React.Component {
  state = {
    showKioskNotification: true
  }

  componentDidMount() {
    this.props.dispatch(SessionActions.initializeSession())
  }

  componentDidUpdate(prevProps) {
    if (this.props.kiosk && !prevProps.kiosk) {
      hotkeys('esc', this.removeKiosk)
    }
  }

  componentWillUnmount() {
    hotkeys.unbind('esc', this.removeKiosk)
  }

  removeKiosk = () => {
    this.props.dispatch(BrowsingActions.browsingSetKiosk(false))
    hotkeys.unbind('esc', this.removeKiosk)
  }

  content() {
    if (this.props.loading) {
      return  (
         <div className="container">
           <div className="row">
             <div className="col-md-12">
               <div className='root-loading'>
                 <Spinner />
               </div>
             </div>
           </div>
         </div>
      )
    } else if (this.props.ready) {
      // don't start Router until session has finished loading
      const { history, routes } = this.props
      return <Router history={history} routes={routes} />
    } else {
      return <div className='error'>Failed to initialize session!</div>
    }
  }

  render () {
    if (this.props.kiosk) {
      return (
        <>
          <div className="kiosk-wrapper">
            {this.content()}
          </div>
        </>
      )
    }

    return (
      <ErrorBoundary errorPrefix="Root">
        <div id="outer-wrapper">
          <div id="inner-wrapper">
            <Header />
            <ConnectionStatusBar />
            <main id="main-content">
              { this.props.user && <WipperForm /> }
              <SystemMessages />
              <GuideViewer />
              <FeedbackCreator id="root" />

              <ErrorBoundary errorPrefix="Router">
                { this.content() }
              </ErrorBoundary>

              <div id="main-content-overlay" />
            </main>
            <Footer />
          </div>
        </div>
      </ErrorBoundary>
    )
  }
}

Root.propTypes = {
  // set by libraries
  history: PropTypes.object.isRequired,
  routes: PropTypes.element.isRequired,
  dispatch: PropTypes.func.isRequired,

  // set by our code
  client: PropTypes.object,
  user: PropTypes.object,
  loading: PropTypes.bool
}

const mapState = state => ({
  loading: state.session.loading,
  ready: state.session.ready,
  user: state.session.user,
  kiosk: state.browsing.kiosk,
  client: state.session.client
})

const ConnectedRoot = connect(mapState)(Root)

export default ConnectedRoot
