import React from 'react'
import { createRoot } from 'react-dom/client'
import Cookies from 'universal-cookie'
import { Provider } from 'react-redux'
import $ from 'jquery'
import { browserHistory } from 'react-router'
import { syncHistoryWithStore } from 'react-router-redux'

import Root from 'components/Root'
import routes from 'routes'
import store from 'store'
import 'utils/errors'

import.meta.glob('../images/favicons', { eager: true })


const startApp = function () {
  const
    mountNode = document.querySelector('#root'),
    history = syncHistoryWithStore(browserHistory, store),
    root = createRoot(mountNode)

  root.render(
    <Provider store={ store }>
      <Root history={ history } routes={ routes } />
    </Provider>
  )

  if (window.Cypress) {
    setTimeout(() => window.appReady = true, 0)
  }
}

const rememberPageAndGoToAccounts = () => {
  // remember this page
  const redir = encodeURIComponent(location.pathname)
  // go to accounts to get a token, then redirect here
  location.href = `${import.meta.env.VITE_IO_SIGN_IN_URL}?redirect_to=${redir}`
}

const tokenPresent = () => !!(localStorage?.getItem('token') || new Cookies().get('token'))

const clearToken = () => {
  new Cookies().remove('token')
  localStorage?.removeItem('token')
}

document.addEventListener("DOMContentLoaded", () => {
  // ask accounts if we've got a session
  $.ajax({
    method: 'HEAD',
    url: `${import.meta.env.VITE_IO_URL}/api/v2/sessions`,
    xhrFields: { withCredentials: true }
  })

  .done(() => { // yes we have a session
    if(!tokenPresent()) {
      rememberPageAndGoToAccounts() // no token? go get one
    } else {
      startApp()
    }
  })

  .fail(() => { // no session
    // clear any tokens and start the app
    if(tokenPresent()) { clearToken() }

    startApp()
  })
})
