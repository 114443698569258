import { handleActions } from 'redux-actions'

import log from 'utils/logger'; log()

const initialState = {
  notifications: [],   // active notifications

  modal: {visible: false},
  // by user then by feed
  feed_shares: {}
}

const sharing = handleActions({
  GET_SHARING_NOTIFICATIONS_FULFILLED(state, { payload }) {
    return Object.assign({}, state, {notifications: payload})
  },

  ARCHIVE_SHARING_NOTIFICATION_FULFILLED(state, { payload }) {
    const { notification } = payload
    const nextNotifications = state.notifications.filter((note) => {
      return note.id !== notification.id
    })
    return Object.assign({}, state, {notifications: nextNotifications})
  },

  GET_FEED_SHARES_FULFILLED(state, { payload }) {
    const { feed, feed_shares } = payload

    const currentShares = state.feed_shares[feed.username] || {}
    const nextShares = Object.assign({}, currentShares, { [feed.key]: feed_shares })

    return Object.assign({}, state, {
      feed_shares: Object.assign({}, state.feed_shares, {
        [ feed.username ]: nextShares
      })
    })
  },

  SHARING_TOGGLE_MODAL(state, { payload }) {
    const { isVisible, qrCode } = payload

    return Object.assign({}, state, { modal: { visible: isVisible, qrCode: qrCode } })
  }
}, initialState)

const SharingSelectors = {
  getShares(state, username, feed_key) {
    const forUser = state.sharing.feed_shares[username]
    if (!forUser) return []
    return forUser[feed_key] || []
  }
}

export { SharingSelectors }

export default sharing
