import React from 'react'
import { connect } from 'react-redux'
import FontAwesome from 'react-fontawesome'

import FeedbackActions from 'actions/feedback'
import FeedbackTypes from './FeedbackTypes'
import FormButtons from './FormButtons'


class ForumLink extends React.Component {
  close = () => {
    this.props.dispatch(FeedbackActions.toggleModal(false, this.props.identifier))
  }

  goBack = () => {
    this.props.dispatch(FeedbackActions.toStep('pick_type'))
  }

  codeRequest() {
    if (this.props.subject === FeedbackTypes.PROJECT){
      return (
        <p>
          Due to the amount of help requests we receive, we are unfortunately
          unable to provide personal support on code or hardware project
          related questions.
        </p>
      )
    }
  }

  render() {
    return (
      <div>
        <p>
          It sounds like we could help you better on the Adafruit IO
          forums, <a href={import.meta.env.VITE_FORUMS_URL} target="_blank" rel="noopener">click here to go over there now</a>.
          You can also check
          out <a href='https://discord.gg/adafruit'>the Adafruit Discord chat server</a> which
          has a channel dedicated to Adafruit IO discussion.
        </p>

        <p>
          Community is a huge part of what makes Adafruit special.
          Through the forums and the conversations that happen there, you'll be
          able to get answers from a wider variety of people and share what you
          learn with the community.
        </p>

        { this.codeRequest() }

        <FormButtons>
          <button className='btn' onClick={this.goBack}>
            <FontAwesome name='chevron-left' /> GO BACK
          </button>
          <button className='btn blue' onClick={this.close}>CLOSE</button>
        </FormButtons>
      </div>
    )
  }
}

const mapState = state => ({
  step: state.feedback.step,
  subject: state.feedback.subject
})

const ConnectedForumLink = connect(mapState)(ForumLink)
export default ConnectedForumLink
