import { useSelector } from 'react-redux'

import { Paths } from 'utils/paths'
import { usernameSelector } from 'selectors/users'


export default (routeName, routeParams={}) => {
  const
    routeGenerator = Paths[routeName],
    sessionUsername = useSelector(usernameSelector)

  if(!routeGenerator) {
    throw new Error(`No entry in Paths named "${ routeName }"`)
  }

  // routeUsername may not be set on initial load
  if(!sessionUsername && !routeParams?.username) {
    throw new Error(`useRoutes() must have access to a session user or be passed a 'username' route param`)
   }

  // username may be overriden by routeParams prop
  const params = { username: sessionUsername, ...routeParams }

  return routeGenerator(params)
}
