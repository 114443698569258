import { flatMap, keyBy, map } from 'lodash'
import { createSelector } from '@reduxjs/toolkit'

export const
  allGroups = state => (state.groups?.groups || []),

  allFeeds = createSelector(allGroups, groups => flatMap(groups, "feeds")),

  // temporary for blockly
  allFeedsWithGroupInfo = createSelector(allGroups, groups =>
    flatMap(groups, group =>
      map(group.feeds, feed => ({
        ...feed,
        groupName: group.name
      }))
    )
  ),

  feedsByKey = createSelector(allFeeds, feeds => keyBy(feeds, "key")) // { [feed.key]: feed, ... }
