import React from 'react'
import { useSelector } from 'react-redux'

import { Paths } from 'utils/paths'
import { usernameSelector } from 'selectors/users'
import Navigation from 'utils/navigation'


const HeaderNavigation = () => {
  const username = useSelector(usernameSelector)
  if (!username) { return }

  return (
    <ul className="main-nav-links">
      <li>
        <button onClick={() => Navigation.go(Paths.devices({ username }))}>
          Devices
        </button>
      </li>
      <li>
        <button onClick={() => Navigation.go(Paths.feeds({ username }))}>
          Feeds
        </button>
      </li>
      <li>
        <button onClick={() => Navigation.go(Paths.dashboards({ username }))}>
          Dashboards
        </button>
      </li>
      <li>
        <button onClick={() => Navigation.go(Paths.actions({ username }))}>
          Actions
        </button>
      </li>
      <li>
        <button onClick={() => Navigation.go(Paths.services({ username }))}>
          Power-Ups
        </button>
      </li>
    </ul>
  )
}

export default HeaderNavigation
