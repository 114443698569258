import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import FontAwesome from 'react-fontawesome'

import { Paths } from 'utils/paths'
import sized from 'components/Mixins/sized'
import SessionActions from 'actions/session'
import CartActions from 'actions/cart'
import { canSeeDesignGuide } from 'selectors/users'
import { NavigationSelectors } from 'reducers/navigation'
import HeaderNavigation from './HeaderNavigation'
import HeaderActions from './HeaderActions'
import log from 'utils/logger' // eslint-disable-line
import MobileHeader from 'components/Header/MobileHeader'

import adafruit_logo from './logo_2x.png'

class UnifiedHeader extends React.Component {
  static propTypes = {
    navigation: PropTypes.object
  }

  constructor() {
    super()

    this.state = {
      dropdownVisible: false,
    }

    this.dropdownBody = React.createRef()
  }

  componentDidMount() {
    this.props.dispatch(CartActions.cartCount())
  }

  globalClickListener = (evt) => {
    // ignore click event happened inside the dropdown menu
    if (this.dropdownBody?.current?.contains(evt.target)) { return }

    // else hide dropdown menu
    this.setState({ dropdownVisible: false }, () => {
      document.removeEventListener('click', this.globalClickListener)
    })
  }

  toggleDropdown = () => {
    const dropdownVisible = !this.state.dropdownVisible

    this.setState({ dropdownVisible }, () => {
      if (dropdownVisible) {
        document.addEventListener('click', this.globalClickListener)
      }
    })
  }

  logOut = () => this.props.dispatch(SessionActions.logoutAndRedirect())

  welcomeMessage() {
    const { user } = this.props

    return user
      ? <><span className="welcome-message">Hi, { user.name }</span> |</>
      : null
  }

  authMenuItems() {
    const { user, showDesignGuide } = this.props

    return user
      ? <>
          { showDesignGuide &&
            <li><a href={ Paths.design_guide({ username: user.username }) }> Design Guide</a></li>
          }
          <li><a href={ Paths.profile({ username: user.username }) }>Adafruit IO Profile</a></li>
          <li><a href={ import.meta.env.VITE_ACCOUNTS_URL }>My Adafruit Account <FontAwesome name="external-link" /></a></li>
          <li><a onClick={ this.logOut }>Sign out</a></li>
        </>
      : <>
          <li><a href={import.meta.env.VITE_IO_SIGN_UP_URL}>Get Started for Free</a></li>
          <li><a href={import.meta.env.VITE_IO_SIGN_IN_URL}>Sign In</a></li>
        </>
  }

  authMenu() {
    const { user, loading } = this.props
    if (loading) { return null }

    if (user) {
      const ddClass = `account-dropdown dropdown ${this.state.dropdownVisible ? 'active' : ''}`
      return (
        <div className={ddClass} ref={this.dropdownBody}>
          <button className="dropdown-label" onClick={this.toggleDropdown}>Account</button>
          <div className="dropdown-container" ref={this.dropdownBody}>
            <ul className="dropdown-content">
              { this.authMenuItems() }
            </ul>
          </div>
        </div>
      )
    } else {
      return (
        <span className='sign-out'>
          <a href={import.meta.env.VITE_IO_SIGN_UP_URL}>Get Started for Free</a>
          <a className='sign-in' href={import.meta.env.VITE_IO_SIGN_IN_URL}>Sign In</a>
        </span>
      )
    }
  }

  render() {
    return (
      <header id="adafruit-header">
        <MobileHeader {...this.props} logOut={this.logOut} />

        <div id="large-header">
          <div className="first-row">
            <div className="row-content">
              <nav aria-label="Adafruit Applications Menu">
                <ul className="application-links">
                  <li><a href={import.meta.env.VITE_SHOP_URL}>Shop</a></li>
                  <li><a href={import.meta.env.VITE_LEARN_URL}>Learn</a></li>
                  <li><a href={import.meta.env.VITE_BLOG_URL}>Blog</a></li>
                  <li><a href={import.meta.env.VITE_FORUMS_URL}>Forums</a></li>
                  <li><a href='https://www.youtube.com/adafruit/live'>LIVE!</a></li>
                  <li><a href='https://www.adabox.com'>AdaBox</a></li>
                  <li className="selected"><a onClick={(evt) => evt.preventDefault()}>IO</a></li>
                </ul>
              </nav>

              <div id="account">
                { this.welcomeMessage() }
                { this.authMenu() }
                <div className="cart">
                  <a href="https://www.adafruit.com/shopping_cart">
                    <FontAwesome name="shopping-cart" /> {this.props.cartCount}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="second-row">
            <div className="row-content">
              <div className="left-content">
                <div id="logo">
                  <a href='/'>
                    <img alt="Adafruit IO Logo" title="Adafruit IO" src={adafruit_logo} />
                  </a>
                </div>
                <HeaderNavigation />
              </div>

              <div className="right-content">
                <HeaderActions />
              </div>
            </div>
          </div>
        </div>
      </header>
    )
  }
}

const mapState = state => ({
  user: state.session.user,
  client: state.session.client,
  loading: state.session.loading,
  navigation: NavigationSelectors.getMenu(state),
  cartCount: state.cart.count,
  showDesignGuide: canSeeDesignGuide(state)
})

const ConnectedUnifiedHeader = connect(mapState)(sized(UnifiedHeader))
export default ConnectedUnifiedHeader
